import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";

import { GlobalContext } from "../../../../state/GlobalState";

import { MdKeyboardBackspace } from "react-icons/md";

const About = () => {
  const { pageToShow, setPageToShow } = useContext(GlobalContext);
  return (
    <AboutWrapper pageToShow={pageToShow}>
      <AboutHeader>
        <ReturnButtonWrapper>
          <ReturnButton
            onClick={() => {
              setPageToShow("");
            }}
          />
        </ReturnButtonWrapper>
        <Header>About</Header>
      </AboutHeader>
      <AboutSection>
        <AboutText>
          {`Welcome! 
              
I'm half System Information Manager and half Full Stack Developer, who enjoys building things that live on the internet ocean 🐳.

I develop exceptional websites and web apps that provide intuitive, pixel-perfect user interfaces, UI effects, animations and creating intuitive, dynamic user experiences with efficient and modern backends.

Here are a few technologies I've been working with:

- HTML & (S)CSS
- JavaScript (ES6+)
- React
- Redux
- ContextApi
- Node.js
- MongoDB
- RestApi

And more.. 🤓
`}
        </AboutText>
      </AboutSection>
    </AboutWrapper>
  );
};

export default About;

const AboutWrapper = styled.div`
  display: ${({ pageToShow }) => (pageToShow === "about" ? "block" : "none")};
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background: rgb(93, 109, 126);
  background: white;
  z-index: 2;
  background-image: url("https://perroninstitute.org/wp-content/uploads/2015/08/the%20Perron%20Institute-banner-background-white.jpg");
  background-image: url("https://subtlepatterns.com/patterns/gplaypattern.png");
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

const AboutHeader = styled.div`
  width: 100%;
  border-radius: 20px 20px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 10%;
  background: rgba(72, 98, 99, 0.9);
  border-bottom: 1px solid #aeba74;
  -webkit-box-shadow: 0px 9px 5px 0px rgba(82, 82, 82, 0.5);
  box-shadow: 0px 9px 5px 0px rgba(82, 82, 82, 0.5);
  position: relative;
  z-index: 3;
`;

const Header = styled.h1`
  width: 100%;
  height: 100%;
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-family: "Alfa Slab One", cursive;
`;

const ReturnButtonWrapper = styled.div`
  width: 15%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 0;
`;
const ReturnButton = styled(MdKeyboardBackspace)`
  height: 60%;
  width: 60%;
  :hover {
    cursor: pointer;
    color: white;
  }
`;

const AboutSection = styled.div`
  width: 100%;
  height: 90%;
  background-image: url("cardBackground.png");
  background-position: center center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0 0 20px 20px;
  z-index: 2;
  overflow: hidden;
  /* border: 1px solid black; */
  /* border: 1px solid red; */
  position: relative;

  /* ::after {
    content: "";
    position: absolute;
    height: 45%;
    width: 50%;
    border: 3px solid #5c7b7d;
    background-image: url("myPhoto.jpg");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    bottom: -55px;
    right: -40px;
    z-index: 2;
    border-radius: 10%;
    border-radius: 50%;
    -webkit-box-shadow: -3px -3px 5px 0px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: -3px -3px 5px 0px rgba(0, 0, 0, 0.33);
    box-shadow: -3px -3px 5px 0px rgba(0, 0, 0, 0.33);
  } */
  ::before {
    content: "";
    position: absolute;
    height: 50%;
    width: 70%;
    /* border: 3px solid #5c7b7d; */
    background-image: url("codeCircle.png");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    bottom: 30%;
    left: 20%;
    z-index: 1;
    opacity: 0.2;
    border-radius: 10%;
  }
`;

const AboutText = styled.p`
  border-radius: 0 0 20px 20px;
  display: felx;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: auto;
  color: #353b5f;
  /* align-items:center; */
  height: 100%;
  width: 100%;
  white-space: pre-line;
  font-size: 1rem;
  line-height: 22px;
  padding: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-family: "Mukta", sans-serif;

  @media (max-width: 1600px) {
    font-size: 0.9rem;
    line-height: 18px;
  }

  @media (max-width: 900px) {
    font-size: 0.9rem;
    line-height: 20px;
  }
  @media (max-width: 550px) {
    font-size: 0.8rem;
    line-height: 15px;
  }
  @media (max-width: 400px) {
    font-size: 0.7rem;
    line-height: 13px;
  }
`;
