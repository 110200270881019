import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";

import Project from "./Project";
import projects from "../../../../data/porjectsData";

import { GlobalContext } from "../../../../state/GlobalState";

import { MdKeyboardBackspace } from "react-icons/md";

// import { GlobalContext } from "../../state/globalState";

const ProjectsPage = () => {
  // const { pageSection, setPageSection } = useContext(GlobalContext);

  const { pageToShow, setPageToShow } = useContext(GlobalContext);
  const projectsArray = projects.map(project => {
    return (
      <Project
        projectHeader={project.projectHeader}
        projectDescription={project.projectDescription}
        projectGif={project.projectGif}
        projectLink={project.link}
        projectGit={project.github}
      />
    );
  });

  return (
    <ProjectsWrapper pageToShow={pageToShow}>
      <AboutHeader>
        <ReturnButtonWrapper>
          <ReturnButton
            onClick={() => {
              setPageToShow("");
            }}
          />
        </ReturnButtonWrapper>
        <Header>My Projects</Header>
      </AboutHeader>
      <ProjectsBox>
        <AllProjects>{projectsArray}</AllProjects>
      </ProjectsBox>
    </ProjectsWrapper>
  );
};

export default ProjectsPage;

const ProjectsWrapper = styled.div`
  display: ${({ pageToShow }) =>
    pageToShow === "projects" ? "block" : "none"};
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background: rgb(93, 109, 126);
  background: white;
  z-index: 2;
  background-image: url("cardBackground.png");
`;
const AboutHeader = styled.div`
  width: 100%;
  border-radius: 20px 20px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 10%;
  background: rgba(60, 85, 109, 0.9);
  border-bottom: 1px solid #aeba74;
  -webkit-box-shadow: 0px 9px 5px 0px rgba(84, 83, 120, 0.5);
  box-shadow: 0px 9px 5px 0px rgba(84, 83, 120, 0.5);
  position: relative;
`;

const Header = styled.h1`
  width: 100%;
  height: 100%;
  font-size: 1.9rem;
  letter-spacing: 2px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-family: "Alfa Slab One", cursive;
`;

const ReturnButtonWrapper = styled.div`
  width: 15%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 0;
`;
const ReturnButton = styled(MdKeyboardBackspace)`
  height: 60%;
  width: 60%;
  :hover {
    cursor: pointer;
    color: white;
  }
`;

const ProjectsBox = styled.div`
  /* border: 1px solid black; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* align-items: center; */
  /* backdrop-filter: blur(3px); */
  height: 90%;
  width: 100%;
  border-radius: 50px;
  -webkit-box-shadow: 14px 17px 14px 0px rgba(82, 82, 82, 0.17);
  box-shadow: 14px 17px 14px 0px rgba(82, 82, 82, 0.17);
  /* min-width: 300px; */
  /* min-height: 400px; */
  max-width: 1000px;
  max-height: 1200px;
  border-radius: 0 0 20px 20px;
`;

const AllProjects = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0 0 20px 20px;

  @media (max-width: 900px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
`;
