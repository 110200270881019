import React, { useState, useContext } from "react";
import styled, { keyframes } from "styled-components";

import "./styles.css";

import GlobalStyle from "../style/GlobalStyle";
import { GlobalContext } from "../state/GlobalState";

import { FaHandPointer } from "react-icons/fa";
import { FaRegHandPointer } from "react-icons/fa";
import { GiClick } from "react-icons/gi";

import Card from "./Card/Card";
import CodeMagazineTwo from "./magazines/CodeMagazineTwo";
import CodeMagazineThree from "./magazines/CodeMagazineThree";
import CodeMagazineOne from "./magazines/CodeMagazineOne";

import Terminal from "./Terminal";

const App = () => {
  const { showCard } = useContext(GlobalContext);

  return (
    <AppWrapper>
      {/* <ResumeButton href="https://uploads.codesandbox.io/uploads/user/56f5f12d-458b-40c0-be9c-202261877b4f/-z-9-CV-Oren%20Kesler.pdf">
        Resume
      </ResumeButton> */}
      <CodeMagazineThree />
      <CodeMagazineOne />
      <CodeMagazineTwo />
      <Card />
      <PointedHend showCard={showCard} />
      <Terminal />
      <GlobalStyle />
    </AppWrapper>
  );
};

export default App;

const AppWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url("tableBackGround.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
`;

const ResumeButton = styled.a`
  position: absolute;
  top: 10px;
  background: #172a45;
  border: 1px solid gray;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  z-index: 1;

  @-webkit-keyframes shake {
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
      transform: translate3d(4px, 0, 0);
    }
  }

  -webkit-animation: shake 2s linear infinite;

  animation: shake 2s linear infinite;
`;

const movingHend = keyframes`
0%{
   transform: rotateX(0deg) ;
}
50%{
  transform: rotateX(25deg) ;
}
100%{
  transform: rotateX(50deg) ;
}
`;

const PointedHend = styled(FaHandPointer)`
  display: ${({ showCard }) => (showCard ? "none" : "block")};
  height: 4%;
  width: 4%;
  position: absolute;
  bottom: 40%;
  left: 30%;
  z-index: 3;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  color: #e3c4a8;
  opacity: 0.7;
  animation: ${movingHend} 0.5s linear alternate infinite;
  transform-style: preserve-3d;
  -webkit-animation: ${movingHend} 0.5s linear alternate infinite;
`;
