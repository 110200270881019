import React from "react";
import styled from "styled-components";

import { GoLinkExternal } from "react-icons/go";
import { GoMarkGithub } from "react-icons/go";

const Project = ({
  projectHeader,
  projectDescription,
  projectGif,
  projectLink,
  projectGit
}) => {
  return (
    <ProjectBox>
      <ProjectHeader>{projectHeader}</ProjectHeader>
      <ProjectBody>
        <ProjectDescription>
          {projectDescription}
          <LinksBox>
            <StyledLink
              href={projectLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkIcon />
            </StyledLink>
            <StyledLink
              href={projectGit}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitIcon />
            </StyledLink>
          </LinksBox>
        </ProjectDescription>
        <ProjectPhoneImg>
          <ProjectGif projectGif={projectGif} />
        </ProjectPhoneImg>
      </ProjectBody>
    </ProjectBox>
  );
};

export default Project;

const ProjectBox = styled.div`
  z-index: 2;
  height: 35%;
  width: 45%;
  margin: 10px 0 5px 10px;

  display: -webkit-box;
  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 10%;
  background: white;
  border: 2px solid #7c9dae;
  position: relative;
  -webkit-box-shadow: 8px 7px 5px 0px rgba(82, 82, 82, 0.27);
  box-shadow: 8px 7px 5px 0px rgba(82, 82, 82, 0.27);
  overflow: hidden;

  ::after {
    content: "";
    height: 100%;
    width: 100%;
    background-image: url("reactIcon.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -40%;
    bottom: -40%;
    opacity: 0.3;
    z-index: -1;
  }

  @media (max-width: 900px) {
    width: 80%;
    height: 50%;
  }
`;

const ProjectHeader = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 30px;
  text-decoration: underline;
  color: black;
  font-size: 1.2rem;
  color: #7c9dae;
  font-weight: bold;
  font-family: "Mukta", sans-serif;

  @media (max-width: 1600px) {
    font-size: 1.1rem;
  }
  /* @media (max-width: 1300px) {
    font-size: 0.9rem;
  }
  @media (max-width: 900px) {
    font-size: 0.8rem;
  }
  @media (max-width: 550px) {
    font-size: 0.7rem;
  } */
`;

const ProjectBody = styled.div`
  height: 80%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
`;

const ProjectDescription = styled.div`
  color: black;
  width: 60%;
  height: 90%;
  padding-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  white-space: pre-line;
  font-size: 0.8rem;
  line-height: 12px;
  font-family: "Mukta", sans-serif;

  @media (max-width: 1600px) {
    font-size: 0.6rem;
    line-height: 10px;
  }
  @media (max-width: 1300px) {
    font-size: 0.7rem;
    line-height: 10px;
  }
  @media (max-width: 900px) {
    font-size: 0.9rem;
    line-height: 12px;
  }
  @media (max-width: 550px) {
    font-size: 0.6rem;
    line-height: 12px;
  }
`;

const LinksBox = styled.div`
  width: 60%;
  height: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5rem;
  color: black;
`;

const ProjectPhoneImg = styled.div`
  background-image: url("iphoneImg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 30%;
  position: relative;
`;

const ProjectGif = styled.div`
  /* border: 1px solid red; */
  position: absolute;
  /* top: 32px; */
  top: 10%;
  -webkit-transform: translateX(-47%);
  -ms-transform: translateX(-47%);
  transform: translateX(-47%);
  left: 47%;
  /* z-index: 2; */
  height: 78%;
  width: 84%;
  background-image: ${({ projectGif }) =>
    projectGif ? `url(${projectGif})` : null};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const LinkIcon = styled(GoLinkExternal)`
  :hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
    color: gray;
  }
`;
const GitIcon = styled(GoMarkGithub)`
  :hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
    color: gray;
  }
`;

const StyledLink = styled.a`
  color: #5c6b7d;
  text-decoration: none;
  width: 10%;
  height: 10%;
  :hover {
    cursor: pointer;
  }
`;
