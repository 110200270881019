import React, { useContext } from "react";
import styled from "styled-components";

import { GlobalContext } from "../../../state/GlobalState";

import { DiGithubBadge } from "react-icons/di";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

const FrontSide = () => {
  const { setShowCard } = useContext(GlobalContext);

  return (
    <FrontSideContainer onClick={() => setShowCard(true)}>
      <HeadSection>
        <CardHeader class="test">Oren Kesler</CardHeader>
        <CardSubHeader>Full Stack Web Developer</CardSubHeader>
      </HeadSection>
      <ContactSection>
        {/* <AllLink> */}
        <MailIcon>
          <FiMail />
          <a href="mailto: orenkes12@gmail.com" target="_blank">
            orenkes12@gmail.com
          </a>
        </MailIcon>
        <LinkedinIcon>
          <FaLinkedin />
          <a
            href="https://www.linkedin.com/in/oren-kesler-934245144/"
            target="_blank"
          >
            https://www.linkedin.com/in/oren-kesler-934245144/
          </a>
        </LinkedinIcon>
        <WhatsappIcon>
          <FaWhatsapp />
          <a href="tel:+972546308658">054-6308658</a>
        </WhatsappIcon>
        <GithubIcon>
          <DiGithubBadge />
          <a href="https://github.com/orenkes" target="_blank">
            https://github.com/orenkes
          </a>
        </GithubIcon>
        {/* </AllLink> */}
      </ContactSection>
    </FrontSideContainer>
  );
};

export default FrontSide;

const FrontSideContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 20px;
  perspective: 1000px;
  background: whitesmoke;
  position: absolute;
  overflow: hidden;
  display: felx;
  backface-visibility: hidden;

  ::before {
    content: "";
    height: 10%;
    width: 15%;
    background: darkgray;
    position: absolute;
    right: 0;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    z-index: 2;
  }
`;

const HeadSection = styled.div`
  width: 40%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 3px solid grey;
  background: rgba(95, 120, 126, 0.9);
  /* background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png"); */
  z-index: 2;
  /* border: 1px solid red; */
`;

const CardHeader = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(270deg);
  text-align: center;
  font-size: 5rem;
  width: 100%;
  left: 0;
  white-space: nowrap;
  position: absolute;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-family: "Alfa Slab One", cursive;
  @media (max-width: 1000px) {
    font-size: 2.5rem;
    left: -20px;
  }
  @media (max-width: 400px) {
    font-size: 2rem;
    left: -30px;
  }
`;

const CardSubHeader = styled.h2`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  text-align: center;
  font-size: 3rem;
  width: 100%;
  color: whitesmoke;
  /* left: 0; */
  right: -70px;
  white-space: nowrap;
  position: absolute;
  font-family: "Sriracha", cursive;
  @media (max-width: 1300px) {
    font-size: 2.2rem;
    right: -70px;
  }

  @media (max-width: 700px) {
    font-size: 2rem;
    right: -30px;
  }
  @media (max-width: 400px) {
    font-size: 2rem;
    /* right: -100px; */
    right: -30px;
  }
`;

const ContactSection = styled.div`
  background-image: url("cardBackground.png");
  background-position: center center;
  /* height: 120%; */
  width: 60%;
  display: felx;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 2px solid yellow;
  a {
    font-family: "Mukta", sans-serif;
  }
  /* transform: rotate(180deg); */
`;

const LinkedinIcon = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 15%;
  width: 100%;
  font-size: 2rem;
  background: rgba(163, 187, 193, 0.2);

  @media (max-width: 1600px) {
    font-size: 0.9rem;
  }
  @media (max-width: 700px) {
    font-size: 0.5rem;
  }
  @media (max-width: 400px) {
    font-size: 0.5rem;
  }

  a {
    font-size: 1.2rem;
    padding-left: 5px;
    padding-right: 5px;
    color: black;
    text-decoration: none;
    @media (max-width: 1600px) {
      font-size: 0.8rem;
    }
    @media (max-width: 700px) {
      font-size: 0.5rem;
    }
    @media (max-width: 400px) {
      font-size: 0.5rem;
    }
  }
`;

const GithubIcon = styled(LinkedinIcon)``;
const MailIcon = styled(LinkedinIcon)``;
const WhatsappIcon = styled(LinkedinIcon)``;
