import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
// import { useInView } from "react-intersection-observer";

// import { GlobalContext } from "../../state/globalState";

import { FaGithubSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaWhatsappSquare } from "react-icons/fa";
import { MdKeyboardBackspace } from "react-icons/md";

import { GlobalContext } from "../../../../state/GlobalState";

const ContactPage = () => {
  const { pageToShow, setPageToShow } = useContext(GlobalContext);

  const sendmessage = e => {
    e.preventDefault();
    if (
      e.target[0].value === "" ||
      e.target[1].value === "" ||
      e.target[2].value === "" ||
      e.target[3].value === ""
    )
      alert("Please fill all the fields :) ");
    else {
      const message = {
        senderName: e.target[0].value,
        senderEmail: e.target[1].value,
        messageSubject: e.target[2].value,
        message: e.target[3].value
      };

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(message)
      };

      fetch("https://cv-database.now.sh/messagesend", options);

      e.target[0].value = "";
      e.target[1].value = "";
      e.target[2].value = "";
      e.target[3].value = "";

      alert("We will be in touch :) ");
    }
  };
  return (
    <ContactWrapper pageToShow={pageToShow}>
      <AboutHeader>
        <ReturnButtonWrapper>
          <ReturnButton
            onClick={() => {
              setPageToShow("");
            }}
          />
        </ReturnButtonWrapper>
        <Header>Contact</Header>
      </AboutHeader>
      {/* <ContactConatiner> */}
      <ContactBox>
        <FormHeader>Let's Have A Talk! 🙂</FormHeader>
        <ContactForm onSubmit={sendmessage}>
          <NameIuput type="text" placeholder="Full Name*" />
          <EmailInput type="text" placeholder="Email Address*" />
          <SubjectInput type="text" placeholder="Subject*" />
          <MessageInput rows="4" placeholder="Your Message... :)*" />
          <SubmitButton>Send</SubmitButton>
        </ContactForm>
        <LinkToMedia>
          <LinkedinLink>
            <HrefLink
              href="https://www.linkedin.com/in/oren-kesler-934245144/"
              target="_blank"
            >
              <FaLinkedin />
            </HrefLink>
          </LinkedinLink>
          <FacebookLink>
            <HrefLink
              href="https://www.facebook.com/oren.kesler"
              target="_blank"
            >
              <FaFacebookSquare />
            </HrefLink>
          </FacebookLink>
          <MailLink>
            <HrefLink href="mailto: orenkes12@gmail.com">
              <IoMdMail />
            </HrefLink>
          </MailLink>
          <WhatsappLink>
            <HrefLink href="tel:+972546308658">
              <FaWhatsappSquare />
            </HrefLink>
          </WhatsappLink>
          <GithubLink>
            <HrefLink href="https://github.com/orenkes" target="_blank">
              <FaGithubSquare />
            </HrefLink>
          </GithubLink>
        </LinkToMedia>
      </ContactBox>
      {/* </ContactConatiner> */}
    </ContactWrapper>
  );
};

export default ContactPage;

const ContactWrapper = styled.div`
  display: ${({ pageToShow }) => (pageToShow === "contact" ? "block" : "none")};
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background: rgb(93, 109, 126);
  background: white;
  z-index: 2;
  background-image: url("https://perroninstitute.org/wp-content/uploads/2015/08/the%20Perron%20Institute-banner-background-white.jpg");
  background-image: url("https://subtlepatterns.com/patterns/gplaypattern.png");
`;

const AboutHeader = styled.div`
  width: 100%;
  border-radius: 20px 20px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 10%;
  background: rgba(72, 98, 99, 0.9);
  border-bottom: 1px solid #aeba74;
  -webkit-box-shadow: 0px 9px 5px 0px rgba(82, 82, 82, 0.5);
  box-shadow: 0px 9px 5px 0px rgba(82, 82, 82, 0.5);
  position: relative;
`;

const Header = styled.h1`
  width: 100%;
  height: 100%;
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-family: "Alfa Slab One", cursive;
`;

const ReturnButtonWrapper = styled.div`
  width: 15%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 0;
`;
const ReturnButton = styled(MdKeyboardBackspace)`
  height: 60%;
  width: 60%;
  :hover {
    cursor: pointer;
    color: white;
  }
`;
const ContactBox = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 90%;
  width: 100%;
  -webkit-box-shadow: 14px 17px 14px 0px rgba(82, 82, 82, 0.17);
  box-shadow: 14px 17px 14px 0px rgba(82, 82, 82, 0.17);
  max-width: 1000px;
  max-height: 1200px;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    height: 35%;
    width: 70%;
    /* transform: rotate(-30deg); */
    /* border: 3px solid #5c7b7d; */
    background-image: url("mern-img.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.4;
    bottom: 5%;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(-15deg);
    -ms-transform: translateX(-50%) rotate(-15deg);
    transform: translateX(-50%) rotate(-15deg);
    z-index: -1;
    border-radius: 10%;
    overflow: hidden;
  }
`;
const FormHeader = styled.div`
  width: 100%;
  height: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  font-family: "Mukta", sans-serif;
`;

const ContactForm = styled.form`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 70%;
  width: 100%;
`;

const NameIuput = styled.input`
  padding-top: 10px;
  width: 75%;
  height: 3%;
  border-radius: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  border: 1px solid #94c0c2;
  -webkit-box-shadow: inset -3px -4px 5px 0px rgba(0, 0, 0, 0.11);
  box-shadow: inset -3px -4px 5px 0px rgba(0, 0, 0, 0.11);
  outline: none;
  font-size: 0.8rem;
  :placeholder-shown {
    font-size: 0.6rem;
  }
  /* background: lightgrey; */
`;
const EmailInput = styled(NameIuput)``;
const SubjectInput = styled(NameIuput)``;

const MessageInput = styled.textarea`
  border-radius: 5px;
  width: 75%;
  outline: none;
  border: 1px solid #94c0c2;
  -webkit-box-shadow: inset -3px -4px 5px 0px rgba(0, 0, 0, 0.11);
  box-shadow: inset -3px -4px 5px 0px rgba(0, 0, 0, 0.11);
  padding-top: 10px;
  padding-left: 10px;
`;

const SubmitButton = styled.button`
  color: white;
  border-radius: 5%;
  text-transform: uppercase;
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  background: rgba(72, 98, 99, 0.8);
  -webkit-box-shadow: 4px 9px 5px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 4px 9px 5px 0px rgba(0, 0, 0, 0.14);
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; */
  &:hover {
    cursor: pointer;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
`;

const LinkToMedia = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 20%;
  width: 100%;
  border-radius: 0 0 20px 20px;
  /* border: 1px solid red; */
  color: red;
`;
const LinkedinLink = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: red;
  background: lightgray;
  border-radius: 5px;
  /* flex-basis: 20%; */
  font-size: 2.2rem;
  &:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    background: #939999;
    /* color: red; */
  }
`;
const GithubLink = styled(LinkedinLink)``;
const FacebookLink = styled(LinkedinLink)``;
const MailLink = styled(LinkedinLink)``;
const WhatsappLink = styled(LinkedinLink)``;

const HrefLink = styled.a`
  color: white;
`;
