import React, { useState } from "react";
import Typist from "react-typist";
import styled from "styled-components";

const Terminal = () => {
  const [flag, setFlag] = useState(false);

  if (!flag) {
    setTimeout(() => {
      setFlag(true);
      console.log(flag);
    }, 5500);
  }

  return (
    <AppContiner flag={flag}>
      <Header>{`------ OREN KESLER PROTFOLIO ------`}</Header>
      <br />
      <Typist avgTypingDelay={7} cursor={{ show: false }}>
        <Test>
          <Typist.Delay ms={100} />
          {"Runing Oren-Kesler-Protfolio-Website"}
          <Typist.Delay ms={100} />
          <br />
          <br />
          {"Fetching The Good Stuff: "}
          {"[ "}
          <Typist.Delay ms={100} />
          {"🍫"}
          <Typist.Delay ms={100} />
          {"-🍭"}
          <Typist.Delay ms={100} />
          {"-🍩"}
          <Typist.Delay ms={100} />
          {"-🍪]"}
          <Typist.Delay ms={100} />
          <br />
          <br />
          {"✅ Building The Site Layout  🔨 "}
          <Typist.Delay ms={100} />
          <br />
          <br />
          {"✅ Painting Everything  🎨 "}
          <Typist.Delay ms={100} />
          <br />
          <br />
          {"✅ Polishing All The Edges  🧼 "}
          <Typist.Delay ms={100} />
          <br />
          <br />
          {"And Let's Go!  🚀"}
        </Test>
        <Typist.Delay ms={100} />
      </Typist>
    </AppContiner>
  );
};

export default Terminal;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
`;

const AppContiner = styled.div`
  height: 100vh;
  width: 100vw;
  color: blueviolet;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  z-index:3;
  position: absolute;
  top: 0;
  font-family: 'Quantico', sans-serif;
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
  overflow: hidden;
  /* transform: ${({ flag }) => (flag ? `translateY(-110%)` : null)}; */
  opacity: ${({ flag }) => (flag ? 0 : 1)};
  z-index: ${({ flag }) => (flag ? 0 : 3)};
  transition: opacity  2s ease-out;
`;

const Test = styled.h1`
  color: white;
  font-size: 1.2rem;
  padding-left: 5px;
`;
