import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

const Provider = GlobalContext.Provider;

export const GlobalProvider = ({ children }) => {
  const [showCard, setShowCard] = useState(false);
  const [pageToShow, setPageToShow] = useState("");

  const state = {
    showCard,
    pageToShow
  };

  const action = {
    setShowCard,
    setPageToShow
  };

  return <Provider value={{ ...state, ...action }}>{children}</Provider>;
};
