import React, { useState, useContext } from "react";
import styled, { keyframes } from "styled-components";

import { GlobalContext } from "../../state/GlobalState";

import FrontSide from "./frontSide/main";
import Backside from "./BackSide/main";

const Card = () => {
  const { showCard, setShowCard } = useContext(GlobalContext);

  return (
    <CradContainer showCard={showCard}>
      <FrontSide />
      <Backside setShowCard={setShowCard} />
    </CradContainer>
  );
};

export default Card;

const CradContainer = styled.div`
  height: 80%;
  width: 30%;
  max-width: 1200px;
  min-width: 300px;
  min-height: 400px;
  position: relative;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 20px;
  z-index: 1;

  transform: ${({ showCard }) =>
    showCard
      ? "perspective(2000) rotateX(0deg) rotateY(180deg) rotateZ(0deg) translateZ(10px)"
      : "rotateX(60deg) rotateY(0deg) rotateZ(25deg)"};
  -webkit-transform: ${({ showCard }) =>
    showCard
      ? "perspective(2000) rotateX(0deg) rotateY(180deg) rotateZ(0deg) translateZ(10px)"
      : "rotateX(60deg) rotateY(0deg) rotateZ(25deg)"};
  -moz-transform: ${({ showCard }) =>
    showCard
      ? "perspective(2000) rotateX(0deg) rotateY(180deg) rotateZ(0deg) translateZ(10px)"
      : "rotateX(60deg) rotateY(0deg) rotateZ(25deg)"};
  -ms-transform: ${({ showCard }) =>
    showCard
      ? "perspective(2000) rotateX(0deg) rotateY(180deg) rotateZ(0deg) translateZ(10px)"
      : "rotateX(60deg) rotateY(0deg) rotateZ(25deg)"};
  -o-transform: ${({ showCard }) =>
    showCard
      ? "perspective(2000) rotateX(0deg) rotateY(180deg) rotateZ(0deg) translateZ(10px)"
      : "rotateX(60deg) rotateY(0deg) rotateZ(25deg)"};

  @media (max-width: 1300px) {
    width: 55%;
    height: 75%;
  }
  @media (max-width: 1000px) {
    width: 55%;
    height: 70%;
  }

  @media (max-width: 750px) {
    width: 35%;
    height: 70%;
    /* border: 5px solid red; */
  }
  @media (max-width: 400px) {
    width: 60%;
    height: 75%;
    /* border: 5px solid red; */
  }

  :hover {
    border: 2px solid gold;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    @media (max-width: 700px) {
      border: none;
    }
  }
`;
