import React from "react";
import styled from "styled-components";

const TimeMagazine = () => {
  return <CradContainer src="magazine3.jpg" />;
};

export default TimeMagazine;

const CradContainer = styled.img`
  height: 69%;
  width: 28%;
  max-width: 1200px;
  position: absolute;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
  transform-style: preserve-3d;
  transition: all 0.5s ease;
  transform: rotateX(60deg) rotateY(0deg) rotateZ(25deg) translateX(-100px)
    translateY(-150px);

  @media (max-width: 1300px) {
    width: 65%;
    height: 70%;
  }
  @media (max-width: 1000px) {
    width: 60%;
    height: 65%;
  }

  @media (max-width: 750px) {
    width: 55%;
    height: 60%;
    /* border: 5px solid red; */
  }
  @media (max-width: 400px) {
    width: 50%;
    height: 55%;
    /* border: 5px solid red; */
  }
`;
