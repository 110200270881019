const projects = [
  {
    projectHeader: "Robogram",
    projectDescription: `
    Just like instagram but with robots :)

    - Built with React
    - Styled by styled components
    - Full CRUD web
    - Using Node.js and express for back-end
    - Connected to mongoDB
     `,
    projectGif: "instagram.gif",
    link: "https://oren-kesler-robogram.netlify.com/",
    github: "https://github.com/orenkes/Robogram"
  },
  {
    projectHeader: "CodeDown",
    projectDescription: `
    A retro truvia video game

    - Built with React
    - Styled by styled components
    - Responsive music and sound effects controls
    - 3 Levels of difficulty
    - based on user selection
    `,
    projectGif: "game.gif",
    link: "https://oren-kesler-codedown.netlify.com/",
    github: "https://github.com/orenkes/CodeDown"
  },
  {
    projectHeader: "Play",
    projectDescription: `    
    A cool music app

    - Built with React 
    - Styled by styled components
    - Responsive music and sound effects controls
    - Using Context Api
    - Fully responsive`,
    projectGif: "play.gif",
    link: "https://oren-kesler-play.netlify.com/",
    github: "https://github.com/orenkes/Play"
  },
  {
    projectHeader: "Deadline",
    projectDescription: `    
    A personal task manager app

    - Built with React 
    - Using Bcrypt and JWT
    - Redux state management
    - Tested by Jest
    - Full CRUD web`,
    projectGif: "deadline4.gif",
    link: "https://oren-kesler-deadline.netlify.com/",
    github: "https://github.com/orenkes/Deadline"
  },
  {
    projectHeader: "Work Time",
    projectDescription: `
    A personal projects time measurement app

    - Built with React
    - Styled by styled components
    - Full CRUD web
    - Using Bcrypt and JWT
    - Full MERN app
    `,
    projectGif: "worktime.gif",
    link: "https://oren-kesler-worktime.netlify.com/",
    github: "https://github.com/orenkes/Work-Time"
  },
  {
    projectHeader: "Weather App",
    projectDescription: `
    A cool and simple weather app

    - Built with React
    - Styled by styled components
    - Full CRUD web
    - Using accuweather Api's
    - Possibility to save a favorite city to DB
    `,
    projectGif: "whether.gif",
    link: "https://oren-kesler-weatherapp.netlify.com/",
    github: "https://github.com/orenkes/Weather-App-Project"
  }
];

export default projects;
