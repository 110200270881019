import React, { useContext } from "react";
import styled from "styled-components";

import { GlobalContext } from "../../../state/GlobalState";

import AboutPage from "./pages/AboutPage";
import ProjectsPage from "./pages/ProjectsPage";
import ContactPage from "./pages/ContactPage";

import { IoMdClose } from "react-icons/io";

const BackSide = () => {
  const { setPageToShow, setShowCard } = useContext(GlobalContext);

  return (
    <BackSideContainer>
      <AboutHeader>
        <ReturnButtonWrapper>
          <ReturnButton onClick={() => setShowCard(false)} />
        </ReturnButtonWrapper>
        <Header>Oren Kesler Portfolio</Header>
      </AboutHeader>
      <BackSideLinksSection>
        <About onClick={() => setPageToShow("about")}>
          <AboutText>About</AboutText>
        </About>
        <AboutPage />
        <Projects onClick={() => setPageToShow("projects")}>
          <ProjectsText>My Projects</ProjectsText>
        </Projects>
        <ProjectsPage />
        <Contact onClick={() => setPageToShow("contact")}>
          <ContactText>Contact</ContactText>
        </Contact>
        <ContactPage />
      </BackSideLinksSection>
    </BackSideContainer>
  );
};

export default BackSide;

const BackSideContainer = styled.div`
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotateY(180deg) scale(1.2);
  transform: rotateY(180deg) scale(1.2);
  -webkit-perspective: 2000px;
  perspective: 2000px;
  background-image: url("cardBackground.png");
  background-position: center center;
  height: 100%;
  width: 100%;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  border-radius: 20px;
  /* border: 1px solid black; */
`;

const AboutHeader = styled.div`
  width: 100%;
  border-radius: 20px 20px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 10%;
  background: rgba(83, 100, 120, 0.9);
  border-bottom: 1px solid #aeba74;
  -webkit-box-shadow: 0px 9px 5px 0px rgba(84, 83, 120, 0.5);
  box-shadow: 0px 9px 5px 0px rgba(84, 83, 120, 0.5);
  position: relative;
`;

const Header = styled.h1`
  width: 100%;
  height: 100%;
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-family: "Alfa Slab One", cursive;

  @media (max-width: 1600px) {
    font-size: 1.7rem;
  }

  @media (max-width: 550px) {
    font-size: 1rem;
  }
`;

const ReturnButtonWrapper = styled.div`
  width: 15%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 0;
`;
const ReturnButton = styled(IoMdClose)`
  height: 60%;
  width: 60%;
  :hover {
    cursor: pointer;
    color: white;
  }
`;

const BackSideLinksSection = styled.div`
  height: 80%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`;

const About = styled.div`
  height: 33%;
  width: 80%;
  border-bottom: 1px solid gray;
  position: relative;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const Projects = styled(About)`
  color: rgb(83, 100, 120);
`;
const Contact = styled(About)`
  color: rgb(72, 98, 99);
`;

const AboutText = styled.h3`
  /* width: 100%; */
  /* height: 100%; */
  /* border: 1px solid black; */
  font-size: 2.5rem;
  color: #605e81;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-family: "Alfa Slab One", cursive;
  :hover {
    cursor: pointer;
    -webkit-filter: blur(3);
    filter: blur(3);

    :hover::after {
      width: 100%;
      -webkit-transition: 0.1s linear;
      -o-transition: 0.1s linear;
      transition: 0.1s linear;
    }
  }

  ::after {
    content: "";
    height: 100%;
    width: 0%;
    border-bottom: 2px solid #5d7785;
    position: absolute;
  }

  @media (max-width: 1000px) {
    font-size: 1.5rem;
  }

  @media (max-width: 400px) {
    font-size: 1rem;
  }
`;

const ProjectsText = styled(AboutText)`
  color: rgb(83, 100, 120);
`;

const ContactText = styled(AboutText)`
  color: rgb(72, 98, 99);
`;
